import { message } from 'antd';
import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { BatchModel } from "@common/domain/models/Batch";
import { CourseModel, IEnrolledCourse } from "@common/domain/models/Course";
import { CourseRepository } from "@common/domain/repositories/CourseRepository";

import { certifiedCoursesStatus, PAGINATION_LIMIT, WebViewMessageType } from "@constants/config";

import { fetchBatchesDetails, fetchBatchMembersCount } from "../batch/actions";
import { fetchBatchesDetailsSaga, fetchBatchStudentsCountSaga } from "../batch/sagas";
import { getBatchByIdSelector } from "../batch/selectors";
import { IEngagementReportPayload } from "../facultyReport/interface";
import { fetchCertifiedCourses } from "../institutes/actions";
import { fetchCoursesSaga } from "../institutes/sagas";
import { fetchCourseRootMapByIdSelector } from "../institutes/selectors";
import {
  connectDiscourseFail,
  connectDiscourseSuccess,
  courseActions,
  enrollToCourseFailure,
  enrollToCourseSuccess,
  fetchActivityStatusFail,
  fetchActivityStatusSuccess,
  fetchCertifiedFacultyCoursesFailure,
  fetchCertifiedFacultyCoursesSuccess,
  fetchCourseActivityPercentageFail,
  fetchCourseActivityPercentageSuccess,
  fetchCourseCompetenciesFailure,
  fetchCourseCompetenciesSuccess,
  fetchCourseContent,
  fetchCourseContentFailure,
  fetchCourseContentSuccess,
  fetchCourseTagsFailure,
  fetchCourseTagsSuccess,
  fetchEnrolledCoursesFail,
  fetchEnrolledCoursesSuccess,
  fetchFacultyCoursesFail,
  fetchFacultyCoursesSuccess,
  fetchLatestCoursesByTagsFailure,
  fetchLatestCoursesByTagsSuccess,
  fetchLmsSessionFail,
  fetchLmsSessionSuccess,
  fetchMultipleCourseDetails,
  fetchMultipleCourseDetailsFailure,
  fetchMultipleCourseDetailsSuccess,
  fetchOpenCourseListFailure,
  fetchOpenCourseListSuccess,
  getCertificateIdFail,
  getCertificateIdSuccess,
  IConnectDicourseAction,
  IEnrollCourseAction,
  IEnrollToCourseSuccessPayload,
  IFetchActivityStatusAction,
  IFetchCertifiedFacultyCourseAction,
  IFetchCourseCompetenciesAction,
  IFetchCourseContentAction,
  IFetchCourseDetailsAction,
  IFetchCourseTocAction,
  IFetchOpenCourseListAction,
  IGetCertificateIdAction,
  updateLastVisitedTimeFail,
  updateLastVisitedTimeSuccess,
  IFtechAiLessonMappingAction,
  fetchAiLessonMappingSuccess,
  fetchAiLessonMappingFail,
  fetchEnrolledCourseKeysSuccess,
  fetchEnrolledCourseKeysFail,
  getMicroCertificateIdSuccess,
  getMicroCertificateIdFail,
  IFetchForumDetailsByIdAction,
  getForumDetailsByCourseIdSuccess,
  getForumDetailsByCourseIdFail,
  IDiscourseSyncToGroupAction,
  discourseSyncToGroupSuccess,
  discourseSyncToGroupFail,
  fetchDefaultOpenCourseListSuccess,
  fetchDefaultOpenCourseListFailure,
  fetchWadhwaniCoursesFail,
  fetchWadhwaniCoursesSuccess,
  fetchInstituteCoursesFail,
  fetchInstituteCoursesSuccess,
  IFetchInstituteCourseAction,
  getCourseDetailFromCourseKeySuccess,
  getCourseDetailFromCourseKeyFail
} from "./actions";



import { getCourseByIdSelector } from './selectors';
import { isWebView } from "@common/redux/auth/selectors";
import { DataUtility } from "@utils/DataUtility";
import { getErrorCodes } from "@utils/ErrorMessageUtils";

export function* fetchMultipleCoursesSaga(action: IFetchCourseDetailsAction) {
  try {
    const courseInfoArray = [];
    const { courseIds } = action.payload;
    if (courseIds && courseIds.length > 0) {
      const MAX_SIZE = PAGINATION_LIMIT;
      const courseIdArrays = [];
      for (let i = 0; i < courseIds.length; i += MAX_SIZE) {
        courseIdArrays.push(courseIds.slice(i, i + MAX_SIZE));
      }
      for (let j = 0; j < courseIdArrays.length; j++) {
        const courses = yield call(CourseRepository.fetchCourses, courseIdArrays[j]);
        courseInfoArray.push(courses);
      }
    }
    yield put(fetchMultipleCourseDetailsSuccess(courseInfoArray.flat()));
  } catch (error) {
    yield put(fetchMultipleCourseDetailsFailure(error.message));
  }
}

export function* fetchCourseCompetenciesSaga(action: IFetchCourseCompetenciesAction) {
  try {
    const { courseId } = action.payload;

    const courseCompetencies = yield call(
      CourseRepository.fetchCourseCompetencies,
      courseId
    );
    yield put(fetchCourseCompetenciesSuccess(courseCompetencies));
  } catch (error) {
    yield put(fetchCourseCompetenciesFailure(error.message));
  }
}

export function* fetchCourseTagsSaga(action: IFetchCourseCompetenciesAction) {
  try {
    const { courseId } = action.payload;
    const courseTags = yield call(CourseRepository.fetchCourseTags, courseId);
    yield put(fetchCourseTagsSuccess(courseTags));
  } catch (error) {
    yield put(fetchCourseTagsFailure(error.message));
  }
}


export function* fetchFacultyCoursesSaga(action) {
  try {
    const courseInfoArray = [];
    const courses = yield call(CourseRepository.fetchFacultyCourses, action.payload);
    const courseIds = courses.courseIds;
    if (courseIds && courseIds.length > 0) {
      const MAX_SIZE = PAGINATION_LIMIT;
      const courseIdArrays = [];
      for (let i = 0; i < courseIds.length; i += MAX_SIZE) {
        courseIdArrays.push(courseIds.slice(i, i + MAX_SIZE));
      }
      for (let j = 0; j < courseIdArrays.length; j++) {
        yield call(fetchMultipleCoursesSaga, fetchMultipleCourseDetails(courseIdArrays[j]))
      }
      for (let k = 0; k < courseIds.length; k++) {
        const course: CourseModel = yield select(getCourseByIdSelector, courseIds[k]);
        courseInfoArray.push(course);
      }
    }
    yield put(fetchFacultyCoursesSuccess(courseInfoArray));
  } catch (error) {
    yield put(fetchFacultyCoursesFail(error.message));
  }
}
export function* fetchEnrolledCoursesSaga(action) {
  try {
    let response = [];
    const { courses, count } = yield call(CourseRepository.fetchEnrolledCourses, action.payload);
    if (!action.payload.callEnrollCourseResponseOnly) {
      const courseIds = courses.map((course) => course.courseId);
      const filteredBatchId = courses.filter((course) => course.batchId);
      const batchIds = filteredBatchId.map((course) => course.batchId);

      if (batchIds && batchIds?.length > 0) {
        yield call(fetchBatchesDetailsSaga, fetchBatchesDetails(batchIds, false));
        yield call(fetchBatchStudentsCountSaga, fetchBatchMembersCount(batchIds));
      }
      if (courseIds && courseIds?.length > 0) {
        yield call(fetchMultipleCoursesSaga, fetchMultipleCourseDetails(courseIds));
      }
      for (let i = 0; i < courses.length; i++) {
        const course: CourseModel = yield select(getCourseByIdSelector, courses[i]?.courseId);
        const batch: BatchModel = yield select(getBatchByIdSelector, courses[i]?.batchId);
        const detailedResponse = {
          ...courses[i],
          courseInfo: course,
          batchInfo: batch
        }
        response.push(detailedResponse);
      }
      yield put(fetchEnrolledCoursesSuccess({ response, total: count, pageNumber: action.payload.pageNumber, status: action.payload.status }));
      for (let i = 0; i < courseIds.length; i++) {
        yield put(fetchCourseContent(action.payload.userId, courseIds[i]));
      }
    } else {
      yield put(fetchEnrolledCoursesSuccess({ response: courses }));
    }

  } catch (error) {
    yield put(fetchEnrolledCoursesFail(error.message,action.payload?.status));
  }
};

export function* fetchCourseActivityPercentageSaga(action) {
  try {
    const response = yield call(CourseRepository.fetchCourseActivityPercentage, action.payload);
    yield put(fetchCourseActivityPercentageSuccess(response));
  } catch (error) {
    yield put(fetchCourseActivityPercentageFail(error.message));
  }
}

export function* fetchCourseTocSaga(action: IFetchCourseTocAction) {
  try {
    const { courseId, studentId,isFromLMSEvent } = action.payload;
    if(isFromLMSEvent){
      yield delay(2000);
    };
    const response = yield call(CourseRepository.fetchCourseToc, courseId, studentId);
    yield put(fetchCourseContentSuccess(response));
  } catch (error) {
    yield put(fetchCourseContentFailure({error: error.message, courseId: action.payload.courseId}));
  }
}


export function* fetchLmsSessionSaga() {
  try {
    const response = yield call(CourseRepository.fetchLmsSession);
    yield put(fetchLmsSessionSuccess(response));
  } catch (error) {
    yield put(fetchLmsSessionFail(error.message));
  }
};

export function* fetchActivityStatusSaga(action: IFetchActivityStatusAction) {
  const { activity, userId, courseId, isFinalAssessment, isFromLMSEvent,isCertifiedAssessment } = action.payload;
  try {
    if (isFromLMSEvent) {
     yield delay(500)
    };
    const response = yield call(CourseRepository.fetchSingleActivityStatus, activity?.instance || activity?.instanceId, userId, courseId, isFinalAssessment,isCertifiedAssessment);
    yield put(fetchActivityStatusSuccess({ response, ...action.payload }));
  } catch (error) {
    yield put(fetchActivityStatusFail(error.message));
  }
};

export function* getCertificateIdSaga(action: IGetCertificateIdAction) {
  try {
    const response = yield call(CourseRepository.getCertificateId, action.payload);
    yield put(getCertificateIdSuccess(response));
  } catch (error) {
    yield put(getCertificateIdFail(error.message));
  }
};

export function* connectDiscourseSaga(action:IConnectDicourseAction){
  try {
    const response = yield call(CourseRepository.connectDiscourse,action.payload);
    yield put(connectDiscourseSuccess(response));
  } catch (error) {
    yield put(connectDiscourseFail(error))
  }
}

export function* updateLastVisitedTimeSaga (action:IGetCertificateIdAction){
  const { userId, courseId } = action.payload
  try {
    const response = yield call(CourseRepository.updateCoursesLastVisitedTime, userId, courseId );
    yield put(updateLastVisitedTimeSuccess(response));
  } catch (error) {
    yield put(updateLastVisitedTimeFail(error))
  }
}

export function* fetchAiLessonMappingSaga(action: IFtechAiLessonMappingAction) {
  const { lessonId } = action.payload;;
  try {
    const response = yield call(CourseRepository.getAiLessonMapping, lessonId);
    yield put(fetchAiLessonMappingSuccess(response));

  } catch (error) {
    yield put(fetchAiLessonMappingFail(error))
  }
};
export function* getLatestCourseDetails(courseRootIds: string[], rows) {
  const courseInfoArray = [];
  if (courseRootIds && courseRootIds.length > 0) {
      const courseIdArrays = [];
      for (let i = 0; i < courseRootIds.length; i += PAGINATION_LIMIT) {
          courseIdArrays.push(courseRootIds.slice(i, i + PAGINATION_LIMIT));
      }
      for (let j = 0; j < courseIdArrays.length; j++) {
        yield call(fetchCoursesSaga, fetchCertifiedCourses('', '', certifiedCoursesStatus.PUBLISHED, courseIdArrays));
      }
      for (let k = 0; k < courseRootIds.length; k++) {
          const course: CourseModel = yield select(fetchCourseRootMapByIdSelector, courseRootIds[k]);
          const courseDetail = { ...course, certifiedOn: rows[k].createdAt, validTill: rows[k].validTill, instituteCourseId: rows[k].id };
          courseInfoArray.push(courseDetail);
      }
      return courseInfoArray;
  }
}

export function* fetchCertifiedFacultyCoursesSaga(action: IFetchCertifiedFacultyCourseAction) {
  try {
      const courses = yield call(CourseRepository.fetchCertifiedFacultyCourses, action.payload);
      const courseRootIds = courses?.rows.map((course) => course.courseRootId);
      const courseInfoArray = yield call(getLatestCourseDetails, DataUtility.uniq(courseRootIds), courses?.rows);
      
      yield put(fetchCertifiedFacultyCoursesSuccess({ courses: courseInfoArray, pageNumber: action.payload.pageNumber, total: courses.count }));
  } catch (e) {
      yield put(fetchCertifiedFacultyCoursesFailure(e.message));
  }
}

export function* fetchLatestCoursesByTagsSaga(action) {
  try {
      const courses = yield call(CourseRepository.fetchLatestCourses, action.payload);
      const tag = JSON.parse(action.payload.tags);
      yield put(fetchLatestCoursesByTagsSuccess({ courses: courses.courses, pageNumber: action.payload.pageNumber, total: courses.total, tag }));
  } catch (e) {
      yield put(fetchLatestCoursesByTagsFailure(e.message));
  }
}


export function* fetchMultipleOpenCoursesSaga(action: IFetchOpenCourseListAction) {
  try {
    const responseData = yield call(CourseRepository.fetchOpenCourseList, action.payload);
    yield put(fetchOpenCourseListSuccess(responseData?.courses || [], responseData?.totalCount, action.payload.instituteId, action.payload.language));
  } catch (error) {
    yield put(fetchOpenCourseListFailure(error.message));
  }
}

export function* enrollToCourseSaga(action: IEnrollCourseAction) {
  try {
    const responseData = yield call(CourseRepository.enrollToCourse, action.payload);
    const response:IEnrollToCourseSuccessPayload = {
      responseData,
      courseId:action.payload.courseId
    }
    yield put(enrollToCourseSuccess(response));
  } catch (e) {
    const error = getErrorCodes(e)
    yield put(enrollToCourseFailure(error));
  }
}

export function* fetchEnrolledCourseKeySaga(action) {
  try {
    const response = yield call(CourseRepository.fetchEnrolledCourseKeys, action.payload);
    yield put(fetchEnrolledCourseKeysSuccess({ response, status: action.payload.status }));
  } catch (error) {
    yield put(fetchEnrolledCourseKeysFail(error));
  }
}

export function* getMicroCertificateIdSaga(action: IGetCertificateIdAction) {
  try {
    const response = yield call(CourseRepository.getCertificateId, action.payload);
    yield put(getMicroCertificateIdSuccess(response,action?.payload?.instanceId));
  } catch (error) {
    yield put(getMicroCertificateIdFail(error.message,action?.payload?.instanceId));
  }
};

export function* getForumDetailsByCourseIdSaga(action: IFetchForumDetailsByIdAction) {
  try {
    const response = yield call(CourseRepository.getForumDetailsById, action.payload);
    yield put(getForumDetailsByCourseIdSuccess(response, action?.payload?.courseKey));
  } catch (error) {
    yield put(getForumDetailsByCourseIdFail(error.message, action?.payload?.courseKey));
  }
};

export function* discourseSyncToGroupSaga(action: IDiscourseSyncToGroupAction) {
  try {
    const response = yield call(CourseRepository.discourseSyncToGroup, action.payload);
    yield put(discourseSyncToGroupSuccess(response));
  } catch (error) {
    yield put(discourseSyncToGroupFail(error.message));
  }
};

export function* fetchDefaultOpenCoursesSaga(action: IFetchOpenCourseListAction) {
  try {
    const responseData = yield call(CourseRepository.fetchOpenCourseList, action.payload);
    yield put(fetchDefaultOpenCourseListSuccess(responseData?.courses || [], responseData?.totalCount, action.payload.instituteId, action.payload.language));
  } catch (error) {
    yield put(fetchDefaultOpenCourseListFailure(error.message));
  }
}

export function* fetchInstituteCoursesSaga(action) {
  try {
    const response = yield call(
      CourseRepository.fetchInstituteCourses,
      action.payload,
    );
    yield put(
      fetchInstituteCoursesSuccess({
        courses: response.courses,
        total: response.total,
        pageNumber: action.payload.pageNumber,
      }),
    );
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(fetchInstituteCoursesFail(error));
  }
}
export function* fetchWadhwaniCoursesSaga(action: IFetchInstituteCourseAction) {
  try {
    const response = yield call(
      CourseRepository.fetchInstituteCourses,
      action.payload,
    );
    yield put(
      fetchWadhwaniCoursesSuccess({
        courses: response.courses,
        total: response.total,
        pageNumber: action.payload.pageNumber,
      }),
    );
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(fetchWadhwaniCoursesFail(error));
  }
}

export function* getCourseDetailFromCourseKeySaga(action) {
  try {
      const course = yield call(CourseRepository.getCourseDetailFromCourseKey, action.payload);
      if(!course){
        const isWebViewer = yield select(isWebView);
        if(isWebViewer) {
          //@ts-ignore
          window.ReactNativeWebView.postMessage(JSON.stringify(
            {
                MESSAGE_TYPE: WebViewMessageType.REDIRECT_TO_HOME
            }
        ));
        }
      }
      yield put(getCourseDetailFromCourseKeySuccess(course));
  } catch (e) {
      yield put(getCourseDetailFromCourseKeyFail(e.message));
  }
}



export function* watchCourses(): any {
  yield takeEvery(courseActions.FETCH_MULTIPLE_COURSES_DETAILS,fetchMultipleCoursesSaga);
  yield takeLatest(courseActions.FETCH_COURSE_COMPETENCIES, fetchCourseCompetenciesSaga);
  yield takeLatest(courseActions.FETCH_COURSE_TAGS, fetchCourseTagsSaga);
  yield takeLatest(courseActions.FETCH_FACULTY_COURSES, fetchFacultyCoursesSaga);
  yield takeEvery(courseActions.FETCH_ENROLLED_COURSES, fetchEnrolledCoursesSaga);
  yield takeEvery(courseActions.FETCH_COURSE_ACTIVITY_PERCENTAGE, fetchCourseActivityPercentageSaga);
  yield takeEvery(courseActions.FETCH_COURSE_CONTENT, fetchCourseTocSaga);
  yield takeLatest(courseActions.FETCH_LMS_SESSION, fetchLmsSessionSaga);
  yield takeLatest(courseActions.FETCH_ACTIVITY_STATUS, fetchActivityStatusSaga);
  yield takeLatest(courseActions.GET_CERTIFICATE_ID,getCertificateIdSaga);
  yield takeLatest(courseActions.CONNECT_DISCOURSE,connectDiscourseSaga);
  yield takeLatest(courseActions.UPDATE_LAST_VISITED_TIME,updateLastVisitedTimeSaga);
  yield takeLatest(courseActions.FETCH_AI_LESSON_MAPPING,fetchAiLessonMappingSaga)
  yield takeLatest(courseActions.FETCH_UPDATED_COURSE_TOC, fetchCourseTocSaga);
  yield takeEvery(courseActions.FETCH_CERTIFIED_FACULTY_COURSES, fetchCertifiedFacultyCoursesSaga);
  yield takeEvery(courseActions.FETCH_LATEST_COURSES_BY_TAGS, fetchLatestCoursesByTagsSaga);
  yield takeEvery(courseActions.FETCH_OPEN_COURSES_LIST, fetchMultipleOpenCoursesSaga);
  yield takeLatest(courseActions.ENROLL_TO_COURSE, enrollToCourseSaga);
  yield takeEvery(courseActions.GET_MICRO_CERTIFICATE_ID, getMicroCertificateIdSaga);
  yield takeEvery(courseActions.GET_FORUM_DETAILS_BYCOURSEID,getForumDetailsByCourseIdSaga);
  yield takeEvery(courseActions.DISCOURSE_SYNC_TO_GROUP,discourseSyncToGroupSaga);
  yield takeEvery(courseActions.FETCH_ENROLLED_COURSE_KEYS, fetchEnrolledCourseKeySaga);
  yield takeEvery(courseActions.FETCH_DEFAULT_OPEN_COURSES_LIST, fetchDefaultOpenCoursesSaga);
  yield takeLatest(courseActions.FETCH_INSTITUTE_COURSES,fetchInstituteCoursesSaga);
  yield takeLatest(courseActions.FETCH_WADHWANI_COURSES,fetchWadhwaniCoursesSaga);
  yield takeLatest(courseActions.GET_COURSE_DETAIL_FROM_COURSEKEY, getCourseDetailFromCourseKeySaga);
};
