import {
    BATCH_CLOSED,
    BATCH_NOT_FOUND,
    ERR_COMPETENCY_DATES_NOT_FOUND,
    ERR_INCORRECT_PASSWORD,
    ERR_MAX_ENROLLMENTS_REACHED,
    ERR_MIN_PASSWORD_LENGTH,
    ERR_PHONE_ALREADY_PRESENT,
    ERR_PROFILE_CREATION_FAILED,
    ERR_USER,
    ERR_USERNAME,
    ERR_USER_CREATION_FAILED,
    ERR_USER_NOT_FOUND,
    ERR_VERIFY_USERNAME,
    INVALID_BATCH_KEY,
    NO_NETWORK,
    USER_ALREADY_EXIST_IN_SAME_BATCH_OR_COURSE,
    USER_NOT_ENROLLED_IN_COURSE,
    ERR_SSO_USER,
    REGISTRATION_LIMIT_EXCEEDED,
    ERR_BATCH_GRACE_PERIOD_ENDED,
    ERR_NOT_OPEN_MODE_COURSE
} from '@common/network/constants';

import { i18n } from '@translations/i18n';

export const getErrorCodes = (e: any): string => {
    let error = e.message;
    if (e.details && e.details.code) {
        error = e.details.code;
    }
    return error;
};

export const ErrorParser = (errorCode: string): string => {
    if (!errorCode) {
        return '';
    }
    switch (errorCode) {
        case NO_NETWORK:
            return i18n.t('API_ERRORS.IAM_SERVICE.NO_NETWORK');
        case ERR_USER:
            return i18n.t('API_ERRORS.IAM_SERVICE.ERR_USER_NOT_FOUND');
        case ERR_USERNAME:
            return i18n.t('API_ERRORS.IAM_SERVICE.ERR_USERNAME');
        case ERR_VERIFY_USERNAME:
            return i18n.t('API_ERRORS.IAM_SERVICE.ERR_VERIFY_USERNAME');
        case ERR_MIN_PASSWORD_LENGTH:
            return i18n.t('API_ERRORS.IAM_SERVICE.ERR_MIN_PASSWORD_LENGTH');
        case ERR_NOT_OPEN_MODE_COURSE:
            return i18n.t('API_ERRORS.IAM_SERVICE.ERR_NOT_OPEN_MODE_COURSE');
        case BATCH_NOT_FOUND:
        case USER_NOT_ENROLLED_IN_COURSE:
            return i18n.t('API_ERRORS.ERR', { context: errorCode });
        case INVALID_BATCH_KEY:
            return i18n.t('dashboard.errors.INVALID_BATCH_KEY');
        case USER_ALREADY_EXIST_IN_SAME_BATCH_OR_COURSE:
            return i18n.t('dashboard.errors.USER_ALREADY_EXIST_IN_SAME_BATCH_OR_COURSE');
        case ERR_MAX_ENROLLMENTS_REACHED:
            return i18n.t('dashboard.errors.ERR_MAX_ENROLLMENTS_REACHED');
        case BATCH_CLOSED:
            return i18n.t('dashboard.errors.BATCH_CLOSED');
        case ERR_COMPETENCY_DATES_NOT_FOUND:
            return i18n.t("dashboard.errors.ERR_COMPETENCY_DATES_NOT_FOUND");
        case ERR_PHONE_ALREADY_PRESENT:
            return i18n.t('API_ERRORS.PROFILE_SERVICE.ERR_PHONE_ALREADY_PRESENT');
        case ERR_MIN_PASSWORD_LENGTH:
            return i18n.t('API_ERRORS.IAM_SERVICE.ERR_MIN_PASSWORD_LENGTH');
        case ERR_USERNAME:
            return i18n.t('API_ERRORS.IAM_SERVICE.ERR_USERNAME');
        case ERR_VERIFY_USERNAME:
            return i18n.t('API_ERRORS.IAM_SERVICE.ERR_VERIFY_USERNAME');
        case ERR_BATCH_GRACE_PERIOD_ENDED:
            return i18n.t('API_ERRORS.ORGANISATION_SERVICE.ERR_BATCH_GRACE_PERIOD_ENDED',);
        case ERR_USER_NOT_FOUND:
        case ERR_INCORRECT_PASSWORD:
        case ERR_USER_CREATION_FAILED:
        case ERR_PROFILE_CREATION_FAILED:
        case ERR_SSO_USER:
        case REGISTRATION_LIMIT_EXCEEDED:
            return i18n.t('API_ERRORS.IAM_SERVICE.ERR', { context: errorCode });
        default:
            return `${i18n.t('API_ERRORS.IAM_SERVICE.SOMETHING_WENT_WRONG')} - ${errorCode}`;
    }
};
