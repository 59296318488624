import Config from '@config/index';
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import firebase from "firebase/app";

export const enum SCREEN_NAME {
  HOME = "HomeScreen",
  CALENDAR = "CalendarScreen",
  CHAT = "ChatScreen",
  LOGIN = "LoginScreen",
  REGISTER = "RegisterScreen",
  CREATE_PROFILE = "CreateProfileScreen",
  FORGOT_PASSWORD = "ForgotPasswordScreen",
  CREATE_PASSWORD = "CreatePasswordScreen",
  GUEST_HOME = "GuestHomeScreen",
  CREATE_BATCH = "CreateBatchScreen",
  USER_VERIFICATION = "UserVerificationScreen",
  USER_SUPPORT = "UserSupportScreen",
  ALL_BATCHES = "AllBatchesScreen",
  USER_PROFILE = "UserProfileScreen",
  EDIT_BATCH = "EditBatchScreen",
  MEETING_DETAIL = "MeetingDetailScreen",
  RESET_PASSWORD = "ResetPasswordScreen",
  INVITE_STUDENT = "InviteStudentScreen",
  MANAGE_STUDENT = "ManageStudentScreen",
  BATCH_DETAIL = "BatchDetailScreen",
  REPORT = "ReportScreen",
  BATCH_LIST = "BatchListScreen",
  COURSE_DETAIL = "CourseDetailScreen",
  TOOLKIT = "toolkit",
  PROGRAM_DETAIL = "ProgramDetailScreen",
  EVENT_DETAIL = "EventDetailScreen"
}

export const enum EVENT_NAME {
  IC_HOMEPAGE_PAGEVIEW = 'ic_homepage_pageview',
  IC_HOMEPAGE_CLICK_LEARNMORE = 'ic_homepage_click_learnmore',
  IC_HOMEPAGE_CLICK_POPULARROLE = 'ic_homepage_click_popularrole',
  IC_HOMEPAGE_INPUT_JOBROLE = 'ic_homepage_input_jobrole',
  IC_HOMEPAGE_CLICK_ENTERJOBROLE = 'ic_homepage_click_enterjobrole',
  IC_ROLEFINDER_CLICK_QUITCHAT = 'ic_rolefinder_click_quitchat',
  IC_ROLEFINDER_CLICK_STARTJOURNEY = 'ic_rolefinder_click_startjourney',
  IC_ROLEFINDER_CLICK_ENTERANOTHERROLE = 'ic_rolefinder_click_enteranotherrole',
  IC_RECENTJOURNEY_CLICK_RESUME = 'ic_recentjourney_click_resume',
  IC_JOURNEYPAGE_CLICK_START = 'ic_journeypage_click_start',
  IC_JOURNEYPAGE_CLICK_RESUME = 'ic_journeypage_click_resume',
  IC_JOURNEYPAGE_CLICK_RETAKE = 'ic_journeypage_click_retake',
  IC_JOURNEYPAGE_CLICK_REATTEMPT = 'ic_journeypage_click_reattempt',
  IC_JOURNEYPAGE_CLICK_PREVIEWQUESTIONS = 'ic_journeypage_click_previewquestions',
  IC_JOURNEYPAGE_CLICK_BACK = 'ic_journeypage_click_back',
  IC_INTERVIEWPAGE_CLICK_PLAYQUESTION = 'ic_interviewpage_click_playquestion',
  IC_INTERVIEWPAGE_CLICK_VIEWTRANSCRIPT = 'ic_interviewpage_click_viewtranscript',
  IC_INTERVIEWPAGE_CLICK_STARTRECORD = 'ic_interviewpage_click_startrecord',
  IC_INTERVIEWPAGE_CLICK_STOPRECORD = 'ic_interviewpage_click_stoprecord',
  IC_FEEDBACKPAGE_CLICK_DETAILFEEDBACK = 'ic_feedbackpage_click_detailfeedback',
  IC_FEEDBACKPAGE_CLICK_BACK = 'ic_feedbackpage_click_back',
  IC_DETAILEDFEED_CLICK_PREVIOUSATTEMPT = 'ic_detailedfeed_click_previousattempt',
  IC_DETAILEDFEED_CLICK_BACK = 'ic_detailedfeed_click_back',
  IC_JOURNEYPAGE_PAGEVIEW = 'ic_journeypage_pageview',
  IC_FEEDBACKPAGE_PAGEVIEW = 'ic_feedbackpage_pageview',
  IC_DETAILEDFEED_PAGEVIEW = 'ic_detailedfeed_pageview',
  IC_DETAILEDFEED_CLICK_CONTENT = 'ic_detailedfeed_click_content',
  IC_DETAILEDFEED_CLICK_CONFIDENCE = 'ic_detailedfeed_click_confidence',
  IC_DETAILEDFEED_CLICK_PROFESSION = 'ic_detailedfeed_click_profession',
  IC_DETAILEDFEED_CLICK_CLARITY = 'ic_detailedfeed_click_clarity',
  IC_DETAILEDFEED_CLICK_ANSWERTRANSCRIPT = 'ic_detailedfeed_click_answertranscript'
}

export class AnalyticsService {

  public static init() {
    firebase.initializeApp(Config.FIREBASE_WEB_CONFIG);
    firebase.analytics().setAnalyticsCollectionEnabled(true);
  }

  public static pageViewAnalytics = (screenName: string) => {
    firebase.analytics().setCurrentScreen(screenName);
  }

  public static setUserId = (uuid: string) => {
    firebase.analytics().setUserId(uuid);
  }

  public static setUserProperties = (propsDict: object) => {
    firebase.analytics().setUserProperties(propsDict);
  }

  public static logEvent = (eventName: string, params?: any) => {
    firebase.analytics().logEvent(eventName, params);
  }

  public static getPageViewEvent = () => {
    return firebase.analytics.EventName.PAGE_VIEW;
  }

}
