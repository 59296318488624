import { BatchModel, IInvitedStudent, IResendOTPResponse } from "@common/domain/models/Batch";
import { BatchDeliveryModel } from "@common/domain/models/BatchDeliveryModel";
import { ProfileModel } from "@common/domain/models/Profile";
import { RMBatchModel } from "@common/domain/models/RMBatchModel";

import { userRoles } from "@redux/constants";

import { BatchCompetenciesModal } from "@models/BatchCompetenciesModal";
import { IEnrollCoursePayload, IEnrollCourseAction } from "@common/redux/course/actions";
import { IFluxStandardAction } from "@store/webInterfaces";
import {
  IAddBatchFaculty,
  IBatchAssessmentDates,
  ICreateBatchPayload,
  IDeleteBatchStudentPayload,
  IFetchUserBatchPayload,
  IJoinBatchMessage,
  IRemoveBatchFaculty,
  IRMAssignedBatch,
  IUpdateBatchPayload,
  IUserBatchPermissions,
} from "./interface";

const actionTypePrefix = "BATCH";

export const batchActions = {
  FETCH_BATCHES_USER_OWNED: `${actionTypePrefix}/FETCH_BATCHES_USER_OWNED`,
  FETCH_BATCHES_USER_OWNED_SUCCESS: `${actionTypePrefix}/FETCH_BATCHES_USER_OWNED_SUCCESS`,
  FETCH_BATCHES_USER_OWNED_FAIL: `${actionTypePrefix}/FETCH_BATCHES_USER_OWNED_FAIL`,

  FETCH_BATCHES_DETAILS: `${actionTypePrefix}/FETCH_BATCHES_DETAILS`,
  FETCH_BATCHES_DETAILS_SUCCESS: `${actionTypePrefix}/FETCH_BATCHES_DETAILS_SUCCESS`,
  FETCH_BATCHES_DETAILS_FAIL: `${actionTypePrefix}/FETCH_BATCHES_DETAILS_FAIL`,

  FETCH_ENROLLED_STUDENT_IN_BATCH: `${actionTypePrefix}/FETCH_ENROLLED_STUDENT_IN_BATCH`,
  FETCH_ENROLLED_STUDENT_IN_BATCH_SUCCESS: `${actionTypePrefix}/FETCH_ENROLLED_STUDENT_IN_BATCH_SUCCESS`,
  FETCH_ENROLLED_STUDENT_IN_BATCH_FAIL: `${actionTypePrefix}/FETCH_ENROLLED_STUDENT_IN_BATCH_FAIL`,

  CLOSE_BATCH: `${actionTypePrefix}/CLOSE_BATCH`,
  CLOSE_BATCH_SUCCESS: `${actionTypePrefix}/CLOSE_BATCH_SUCCESS`,
  CLOSE_BATCH_FAIL: `${actionTypePrefix}/CLOSE_BATCHES_FAIL`,

  FETCH_BATCH_COMPETENCIES: `${actionTypePrefix}/FETCH_BATCH_COMPETENCIES`,
  FETCH_BATCH_COMPETENCIES_SUCCESS: `${actionTypePrefix}/FETCH_BATCH_COMPETENCIES_SUCCESS`,
  FETCH_BATCH_COMPETENCIES_FAIL: `${actionTypePrefix}/FETCH_BATCH_COMPETENCIES_FAIL`,

  ADD_COMPETENCY_DATE: `${actionTypePrefix}/ADD_COMPETENCY_DATE`,
  ADD_COMPETENCY_DATE_SUCCESS: `${actionTypePrefix}/ADD_COMPETENCY_DATE_SUCCESS`,
  ADD_COMPETENCY_DATE_FAIL: `${actionTypePrefix}/ADD_COMPETENCY_DATE_FAIL`,

  FETCH_BATCH_DELIVERY_MODELS: `${actionTypePrefix}/FETCH_BATCH_DELIVERY_MODELS`,
  FETCH_BATCH_DELIVERY_MODELS_SUCCESS: `${actionTypePrefix}/FETCH_BATCH_DELIVERY_MODELS_SUCCESS`,
  FETCH_BATCH_DELIVERY_MODELS_FAILURE: `${actionTypePrefix}/FETCH_BATCH_DELIVERY_MODELS_FAILURE`,

  CREATE_BATCH: `${actionTypePrefix}/CREATE_BATCH`,
  CREATE_BATCH_SUCCESS: `${actionTypePrefix}/CREATE_BATCH_SUCCESS`,
  CREATE_BATCH_FAIL: `${actionTypePrefix}/CREATE_BATCH_FAIL`,

  JOIN_BATCH: `${actionTypePrefix}/JOIN_BATCH`,
  JOIN_BATCH_SUCCESS: `${actionTypePrefix}/JOIN_BATCH_SUCCESS`,
  JOIN_BATCH_FAIL: `${actionTypePrefix}/JOIN_BATCH_FAIL`,
  FETCH_USER_BATCHES: `${actionTypePrefix}/FETCH_USER_BATCHES`,
  FETCH_USER_BATCHES_SUCCESS: `${actionTypePrefix}/FETCH_USER_BATCHES_SUCCESS`,
  FETCH_USER_BATCHES_FAIL: `${actionTypePrefix}/FETCH_USER_BATCHES_FAIL`,

  FETCH_STUDENT_FACULTY_BATCHES: `${actionTypePrefix}/FETCH_STUDENT_FACULTY_BATCHES`,
  FETCH_STUDENT_FACULTY_BATCHES_SUCCESS: `${actionTypePrefix}/FETCH_STUDENT_FACULTY_BATCHES_SUCCESS`,
  FETCH_STUDENT_FACULTY_BATCHES_FAIL: `${actionTypePrefix}/FETCH_STUDENT_FACULTY_BATCHES_FAIL`,

  FETCH_BATCH_MEMBERS_COUNT: `${actionTypePrefix}/FETCH_BATCH_MEMBERS_COUNT`,
  FETCH_BATCH_MEMBERS_COUNT_SUCCESS: `${actionTypePrefix}/FETCH_BATCH_MEMBERS_COUNT_SUCCESS`,
  FETCH_BATCH_MEMBERS_COUNT_FAIL: `${actionTypePrefix}/FETCH_BATCH_MEMBERS_COUNT_FAIL`,
  FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE: `${actionTypePrefix}/FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE`,
  FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE_SUCCESS: `${actionTypePrefix}/FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE_SUCCESS`,
  FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE_FAIL: `${actionTypePrefix}/FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE_FAIL`,

  // All Batches
  FETCH_ALL_BATCHES: `${actionTypePrefix}/FETCH_ALL_BATCHES`,
  FETCH_ALL_BATCHES_SUCCESS: `${actionTypePrefix}/FETCH_ALL_BATCHES_SUCCESS`,
  FETCH_ALL_BATCHES_FAIL: `${actionTypePrefix}/FETCH_ALL_BATCHES_FAIL`,

  RM_FETCH_ASSIGNED_BATCHES: `${actionTypePrefix}/RM_FETCH_ASSIGNED_BATCHES`,
  RM_FETCH_ASSIGNED_BATCHES_SUCCESS: `${actionTypePrefix}/RM_FETCH_ASSIGNED_BATCHES_SUCCESS`,
  RM_FETCH_ASSIGNED_BATCHES_FAIL: `${actionTypePrefix}/RM_FETCH_ASSIGNED_BATCHES_FAIL`,
  RM_FETCH_ASSIGNED_BATCHES_ID_LIST_SUCCESS: `${actionTypePrefix}/RM_FETCH_ASSIGNED_BATCHES_ID_LIST_SUCCESS`,

  // Update Batch
  UPDATE_BATCH: `${actionTypePrefix}/UPDATE_BATCH`,
  UPDATE_BATCH_SUCCESS: `${actionTypePrefix}/UPDATE_BATCH_SUCCESS`,
  UPDATE_BATCH_FAIL: `${actionTypePrefix}/UPDATE_BATCH_FAIL`,

  ADD_FACULTY: `${actionTypePrefix}/ADD_FACULTY`,
  ADD_FACULTY_SUCCESS: `${actionTypePrefix}/ADD_FACULTY_SUCCESS`,
  ADD_FACULTY_FAIL: `${actionTypePrefix}/ADD_FACULTY_FAIL`,

  REMOVE_BATCH_FACULTY: `${actionTypePrefix}/REMOVE_BATCH_FACULTY`,
  REMOVE_BATCH_FACULTY_SUCCESS: `${actionTypePrefix}/REMOVE_BATCH_FACULTY_SUCCESS`,
  REMOVE_BATCH_FACULTY_FAIL: `${actionTypePrefix}/REMOVE_BATCH_FACULTY_FAIL`,

  // Fetch user batch permissions
  FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID: `${actionTypePrefix}/FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID`,
  SET_USER_BATCH_PERMISSIONS_BY_BATCH_ID: `${actionTypePrefix}/SET_USER_BATCH_PERMISSIONS_BY_BATCH_ID`,
  FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID_SUCCESS: `${actionTypePrefix}/FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID_SUCCESS`,
  FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID_FAIL: `${actionTypePrefix}/FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID_FAIL`,

  // manage students actions
  UPLOAD_USERS: `${actionTypePrefix}/UPLOAD_USERS`,
  UPLOAD_USERS_SUCCESS: `${actionTypePrefix}/UPLOAD_USERS_SUCCESS`,
  UPLOAD_USERS_FAIL: `${actionTypePrefix}/UPLOAD_USERS_FAIL`,

  FETCH_STUDENTS: `${actionTypePrefix}/FETCH_STUDENTS`,
  FETCH_STUDENTS_SUCCESS: `${actionTypePrefix}/FETCH_STUDENTS_SUCCESS`,
  FETCH_STUDENTS_FAIL: `${actionTypePrefix}/FETCH_STUDENTS_FAIL`,

  RESEND_OPT: `${actionTypePrefix}/RESNT_OPT`,
  RESEND_OPT_SUCCESS: `${actionTypePrefix}/RESNT_OPT_SUCCESS`,
  RESEND_OPT_FAIL: `${actionTypePrefix}/RESNT_OPT_FAIL`,

  FETCH_INVITED_STUDENTS: `${actionTypePrefix}/FETCH_INVITED_STUDENTS`,
  FETCH_INVITED_STUDENTS_SUCCESS: `${actionTypePrefix}/FETCH_INVITED_STUDENTS_SUCCESS`,
  FETCH_INVITED_STUDENTS_FAIL: `${actionTypePrefix}/FETCH_INVITED_STUDENTS_FAIL`,

  SEARCH_STUDENT: `${actionTypePrefix}/SEARCH_INVITED_STUDENT`,
  SEARCH_STUDENT_SUCCESS: `${actionTypePrefix}/SEARCH_INVITED_STUDENT_SUCCESS`,
  SEARCH_STUDENT_FAIL: `${actionTypePrefix}/SEARCH_INVITED_STUDENT_FAIL`,
  DELETE_BATCH_STUDENT: `${actionTypePrefix}/DELETE_BATCH_STUDENT`,

  DELETE_BATCH_STUDENT_SUCCESS: `${actionTypePrefix}/DELETE_BATCH_STUDENT_SUCCESS`,
  DELETE_BATCH_STUDENT_FAIL: `${actionTypePrefix}/DELETE_BATCH_STUDENT_FAIL`,

  SEARCH_ENROLLED_STUDENT: `${actionTypePrefix}/SEARCH_ENROLLED_STUDENT`,
  SEARCH_ENROLLED_STUDENT_SUCCESS: `${actionTypePrefix}/SEARCH_ENROLLED_STUDENT_SUCCESS`,
  SEARCH_ENROLLED_STUDENT_FAIL: `${actionTypePrefix}/SEARCH_ENROLLED_STUDENT_FAIL`,

  INVITE_SINGLE_STUDENT: `${actionTypePrefix}/INVITE_SINGLE_STUDENT`,
  INVITE_SINGLE_STUDENT_SUCCESS: `${actionTypePrefix}/INVITE_SINGLE_STUDENT_SUCCESS`,
  INVITE_SINGLE_STUDENT_FAIL: `${actionTypePrefix}/INVITE_SINGLE_STUDENT_FAIL`,

  GET_STATUS_OF_BULK_UPLOADING_BYID: `${actionTypePrefix}/GET_STATUS_OF_BULK_UPLOADING_BYID`,
  GET_STATUS_OF_BULK_UPLOADING_BYID_SUCCESS: `${actionTypePrefix}/GET_STATUS_OF_BULK_UPLOADING_BYID_SUCCESS`,
  GET_STATUS_OF_BULK_UPLOADING_BYID_FAIL: `${actionTypePrefix}/GET_STATUS_OF_BULK_UPLOADING_BYID_FAIL`,

  SET_ASSESSMENT_OPENING_DATES: `${actionTypePrefix}/SET_ASSESSMENT_OPENING_DATES`,
  SET_ASSESSMENT_OPENING_DATES_SUCCESS: `${actionTypePrefix}/SET_ASSESSMENT_OPENING_DATES_SUCCESS`,
  SET_ASSESSMENT_OPENING_DATES_FAIL: `${actionTypePrefix}/SET_ASSESSMENT_OPENING_DATES_FAIL`,

  UPDATE_ASSESSMENT_OPENING_DATES: `${actionTypePrefix}/UPDATE_ASSESSMENT_OPENING_DATES`,
  UPDATE_ASSESSMENT_OPENING_DATES_SUCCESS: `${actionTypePrefix}/UPDATE_ASSESSMENT_OPENING_DATES_SUCCESS`,
  UPDATE_ASSESSMENT_OPENING_DATES_FAIL: `${actionTypePrefix}/UPDATE_ASSESSMENT_OPENING_DATES_FAIL`,

  GET_ASSESSMENT_OPENING_DATES: `${actionTypePrefix}/GET_ASSESSMENT_OPENING_DATES`,
  GET_ASSESSMENT_OPENING_DATES_SUCCESS: `${actionTypePrefix}/GET_ASSESSMENT_OPENING_DATES_SUCCESS`,
  GET_ASSESSMENT_OPENING_DATES_FAIL: `${actionTypePrefix}/GET_ASSESSMENT_OPENING_DATES_FAIL`
};

export interface IAssessmentOpeningDate {
  assessmentId: string,
  isFinalAssessment: string,
  openingDate: string
}
export interface ISetAssessmentOpeningDatePayload {
  batchId: string,
  assessments: IAssessmentOpeningDate[]
}

export interface ISetAssessmentOpeningDateAction {
  type: string,
  payload: ISetAssessmentOpeningDatePayload
}

export interface IFetchBatchesUserOwnedAction {
  userId: string;
  status?: string;
  page?: number;
  limit?: number;
  fetchBatchInstitutes?: boolean;
}
export interface ICloseBatchAction {
  type: string;
  payload: {
    batchId: string;
  };
}

export interface IFetchBatchesDetailsAction {
  type: string;
  payload: {
    batchIds: number[];
    shouldFetchBatchStudentProfiles?: boolean;
  };
}
export interface IResendOTPAction {
  type: string;
  payload: {
    emailIds: string[];
    ccEmailIds: string;
    batchKey: string;
    instituteCustomUrl: string;
  }
}
export interface IBulkUploadResponse {
  fileName: string,
  status: string,
  totalNumberOfRows: number,
  totalNumberOfProcessedRows: number,
  fileProcessedCompleted: number,
  fileProcessedPercentage: number,
  totalNumberOfFailedRows: number,
  totalNumberOfSuccessRows: number,
  fileResultUrl: string,
  uploadType: string,
  uploadedBy: string,
  id: string
}
export interface IFetchInvitedStudentsAction {
  type: string;
  payload: {
    batchKey: string;
    page: number;
    limit: number;
    search: string;
  }
}
export interface IUploadUsersAction {
  type: string;
  payload: {
    data
  };
}

export interface IInviteSingleStudentAction {
  type: string;
  payload: {
    batchKey: string;
    studentEmailIds: string
  };
}
export interface IFetchBulkUploadStatusAction {
  type: string;
  payload: {
    bulkUploadId: string;
  };
}

export const fetchBatchesUserOwned = (payload: IFetchBatchesUserOwnedAction): IFluxStandardAction<IFetchBatchesUserOwnedAction> => {
  return {
    type: batchActions.FETCH_BATCHES_USER_OWNED,
    payload
  };
};

export const fetchBatchesUserOwnedSuccess = (batches: BatchModel[], status?: string, page?: number, limit?: number, total?: number) => {
  return {
    type: batchActions.FETCH_BATCHES_USER_OWNED_SUCCESS,
    payload: {
      batches,
      status,
      page,
      limit,
      total
    },
  };
};

export const fetchBatchesUserOwnedFailure = (error: string) => {
  return {
    type: batchActions.FETCH_BATCHES_USER_OWNED_FAIL,
    payload: error,
  };
};

export const closeBatch = (batchId: string): ICloseBatchAction => {
  return {
    type: batchActions.CLOSE_BATCH,
    payload: {
      batchId,
    },
  };
};

export const enrolledStudentInBatchSuccess = (batchId: number, role: userRoles, batchMembers: ProfileModel[], count?: number, page?: number) => ({
  type: batchActions.FETCH_ENROLLED_STUDENT_IN_BATCH_SUCCESS,
  payload: {
    batchId,
    role,
    batchMembers, count, page
  }
});

export const enrolledStudentInBatch = (
  payload: IEnrollCoursePayload): IEnrollCourseAction => {
  return {
    type: batchActions.FETCH_ENROLLED_STUDENT_IN_BATCH,
    payload,
  };
};

export const closeBatchSuccess = (batchStatus: string) => {
  return {
    type: batchActions.CLOSE_BATCH_SUCCESS,
    payload: batchStatus,
  };
};

export const closeBatchFailure = (error: string) => {
  return {
    type: batchActions.CLOSE_BATCH_FAIL,
    payload: error,
  };
};

export const fetchBatchCompetencies = (
  batchId: number
): { type: string; payload: number } => {
  return {
    type: batchActions.FETCH_BATCH_COMPETENCIES,
    payload: batchId,
  };
};

export const fetchBatchCompetenciesSuccess = (
  data: BatchCompetenciesModal[]
): { type: string; payload: BatchCompetenciesModal[] } => {
  return {
    type: batchActions.FETCH_BATCH_COMPETENCIES_SUCCESS,
    payload: data,
  };
};

export const fetchBatchCompetenciesFailure = (
  error: string
): { type: string; payload: string } => {
  return {
    type: batchActions.FETCH_BATCH_COMPETENCIES_FAIL,
    payload: error,
  };
};

export const addCompetencyDate = (
  batchId: number,
  competencyId: string,
  endDate: string,
  startDate?: string,
): { type: string; payload: IBatchAssessmentDates } => {
  return {
    type: batchActions.ADD_COMPETENCY_DATE,
    payload: {
      batchId,
      competencyId,
      endDate,
      startDate,
    },
  };
};

export const addCompetencyDateSuccess = (
  data: string
): { type: string; payload: string } => {
  return {
    type: batchActions.ADD_COMPETENCY_DATE_SUCCESS,
    payload: data,
  };
};

export const addCompetencyDateFailure = (
  error: string
): { type: string; payload: string } => {
  return {
    type: batchActions.ADD_COMPETENCY_DATE_FAIL,
    payload: error,
  };
};

export const fetchBatchDeliveryModels = (): IFluxStandardAction => {
  return {
    type: batchActions.FETCH_BATCH_DELIVERY_MODELS,
  };
};

export const fetchBatchDeliveryModelsSuccess = (
  batchDeliveryModels: BatchDeliveryModel[]
) => {
  return {
    type: batchActions.FETCH_BATCH_DELIVERY_MODELS_SUCCESS,
    payload: batchDeliveryModels,
  };
};

export const fetchBatchDeliveryModelsFailure = (error: string) => {
  return {
    type: batchActions.FETCH_BATCH_DELIVERY_MODELS_FAILURE,
    payload: error,
  };
};

export const createBatch = (
  payload: ICreateBatchPayload
): IFluxStandardAction<ICreateBatchPayload> => {
  return {
    type: batchActions.CREATE_BATCH,
    payload,
  };
};

export const createBatchSuccess = (
  payload: BatchModel
): IFluxStandardAction<BatchModel> => {
  return {
    type: batchActions.CREATE_BATCH_SUCCESS,
    payload,
  };
};

export const createBatchFail = (error: string): IFluxStandardAction<string> => {
  return {
    type: batchActions.CREATE_BATCH_FAIL,
    payload: error,
  };
};

export const fetchBatchesDetails = (
  batchIds: number[], shouldFetchBatchStudentProfiles?: boolean
): IFetchBatchesDetailsAction => {
  return {
    type: batchActions.FETCH_BATCHES_DETAILS,
    payload: {
      batchIds,
      shouldFetchBatchStudentProfiles
    },
  };
};

export const fetchBatchesDetailsSuccess = (batches: BatchModel[]) => {
  return {
    type: batchActions.FETCH_BATCHES_DETAILS_SUCCESS,
    payload: batches,
  };
};

export const fetchBatchesDetailsFailure = (error: string) => {
  return {
    type: batchActions.FETCH_BATCHES_DETAILS_FAIL,
    payload: error,
  };
};

export interface IJoinBatchAction {
  type: string;
  payload: {
    batchKey: string;
  };
}

export const joinBatch = (batchKey: string): IJoinBatchAction => {
  return {
    type: batchActions.JOIN_BATCH,
    payload: {
      batchKey,
    },
  };
};

export const joinBatchSuccess = (joinBatchData: IJoinBatchMessage) => {
  return {
    type: batchActions.JOIN_BATCH_SUCCESS,
    payload: joinBatchData,
  };
};

export const joinBatchFailure = (error: string) => {
  return {
    type: batchActions.JOIN_BATCH_FAIL,
    payload: error,
  };
};
export const fetchUserBatches = (
  payload: IFetchUserBatchPayload
): IFluxStandardAction<IFetchUserBatchPayload> => {
  return {
    type: batchActions.FETCH_USER_BATCHES,
    payload,
  };
};

export const fetchUserBatchesSuccess = (data: BatchModel[]): IFluxStandardAction<BatchModel[]> => {
  return {
    type: batchActions.FETCH_USER_BATCHES_SUCCESS,
    payload: data,
  };
};

export const fetchUserBatchesFailure = (
  error: string
): IFluxStandardAction<string> => {
  return {
    type: batchActions.FETCH_USER_BATCHES_FAIL,
    payload: error,
  };
};

export const fetchStudentFacultyBatches = (
  payload: IFetchUserBatchPayload
): IFluxStandardAction<IFetchUserBatchPayload> => {
  return {
    type: batchActions.FETCH_STUDENT_FACULTY_BATCHES,
    payload,
  };
};

export const fetchStudentFacultyBatchesSuccess = (data: BatchModel[]): IFluxStandardAction<BatchModel[]> => {
  return {
    type: batchActions.FETCH_STUDENT_FACULTY_BATCHES_SUCCESS,
    payload: data,
  };
};

export const fetchStudentFacultyBatchesFailure = (
  error: string
): IFluxStandardAction<string> => {
  return {
    type: batchActions.FETCH_STUDENT_FACULTY_BATCHES_FAIL,
    payload: error,
  };
};

export interface IFetchBatchMembersCountAction {
  type: string;
  batchIds: number[];
}

export const fetchBatchMembersCount = (batchIds: number[]): IFetchBatchMembersCountAction => ({
  type: batchActions.FETCH_BATCH_MEMBERS_COUNT,
  batchIds
});

export const fetchBatchMembersCountSuccess = () => ({
  type: batchActions.FETCH_BATCH_MEMBERS_COUNT_SUCCESS,
});

export const fetchBatchMembersCountFail = (error) => ({
  type: batchActions.FETCH_BATCH_MEMBERS_COUNT_FAIL,
  payload: error
});


export interface IFetchMultipleBatchesMembersAction {
  type: string;
  payload: {
    role: userRoles;
    batchIds: number[],
    search?: string
  };
}

export interface IUpdateBatchAction {
  type: string;
  payload: {
    batchId: number;
    payload: IUpdateBatchPayload;
  };
}

export const fetchMultipleBatchesMembersByRole = (role: userRoles, batchIds: number[], search?: string): IFetchMultipleBatchesMembersAction => ({
  type: batchActions.FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE,
  payload: {
    role,
    batchIds,
    search
  }
});

export const fetchMultipleBatchesMembersByRoleSuccess = (batchId: number, role: userRoles, batchMembers: ProfileModel[]) => ({
  type: batchActions.FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE_SUCCESS,
  payload: {
    batchId,
    role,
    batchMembers
  }
});

export const fetchMultipleBatchesMembersByRoleFail = (error: string) => ({
  type: batchActions.FETCH_MULTIPLE_BATCHES_MEMBERS_BY_ROLE_FAIL,
  payload: error
});

// all batches
export const fetchAllBatches = (page?: number, limit?: number, search?: string,
  status?: string, courseId?: string, batchIds?: number[]) => {
  return {
    type: batchActions.FETCH_ALL_BATCHES,
    payload: {
      page,
      limit,
      search,
      status,
      courseId,
      batchIds,
    }
  }
};

export const fetchAllBatchesSuccess = (batches: BatchModel[], total?: number, page?: number) => ({
  type: batchActions.FETCH_ALL_BATCHES_SUCCESS,
  payload: {
    batches,
    total,
    page,
  }
});

export const fetchAllBatchesFail = (error: string) => ({
  type: batchActions.FETCH_ALL_BATCHES_FAIL,
  payload: error
});

export const rMFetchAssignedBatches = (payload: IRMAssignedBatch) => {
  const { regionalManagerId, page, limit, fetchBatchIdsOnly, status,
    notFetchBatchInstitutes, notFetchStudentsCount } = payload;
  return {
    type: batchActions.RM_FETCH_ASSIGNED_BATCHES,
    payload: {
      regionalManagerId,
      page,
      limit,
      status,
      fetchBatchIdsOnly,
      notFetchBatchInstitutes,
      notFetchStudentsCount
    }
  }
}

export const rMFetchAssignedBatchesSuccess = (batches: RMBatchModel[], total?: number, page?: number) => {
  return {
    type: batchActions.RM_FETCH_ASSIGNED_BATCHES_SUCCESS,
    payload: {
      batches,
      total,
      page,
    }
  }
}

// RM Batch list Action
export const rMFetchAssignedBatchesListSuccess = (payload: number[]) => {
  return {
    type: batchActions.RM_FETCH_ASSIGNED_BATCHES_ID_LIST_SUCCESS,
    payload
  }
}

export const rMFetchAssignedBatchesFail = (error: string) => {
  return {
    type: batchActions.RM_FETCH_ASSIGNED_BATCHES_FAIL,
    payload: error
  }
}

// update batch
export const updateBatch = (batchId: number, payload: IUpdateBatchPayload): IUpdateBatchAction => {
  return {
    type: batchActions.UPDATE_BATCH,
    payload: {
      batchId,
      payload
    }
  }
}

export const updateBatchSuccess = (payload: BatchModel): IFluxStandardAction<BatchModel> => {
  return {
    type: batchActions.UPDATE_BATCH_SUCCESS,
    payload
  }
}

export const addFaculty = (payload: IAddBatchFaculty): IFluxStandardAction<IAddBatchFaculty> => {
  return {
    type: batchActions.ADD_FACULTY,
    payload
  }
}

export const addFacultySuccess = (payload: IAddBatchFaculty): IFluxStandardAction<IAddBatchFaculty> => {
  return {
    type: batchActions.ADD_FACULTY_SUCCESS,
    payload
  }
}

export const updateBatchFail = (error: string): IFluxStandardAction<string> => {
  return {
    type: batchActions.UPDATE_BATCH_FAIL,
    payload: error
  }
}
export const addFacultyFail = (error: string): IFluxStandardAction<string> => {
  return {
    type: batchActions.ADD_FACULTY_FAIL,
    payload: error
  }
}

export const removeBatchFaculty = (payload: IRemoveBatchFaculty): IFluxStandardAction<IRemoveBatchFaculty> => {
  return {
    type: batchActions.REMOVE_BATCH_FACULTY,
    payload
  }
}

export const removeBatchFacultySuccess = (payload: {}): IFluxStandardAction<{}> => {
  return {
    type: batchActions.REMOVE_BATCH_FACULTY_SUCCESS,
    payload
  }
}

// USER PERMISSION BY BATCH ID
export const removeBatchFacultyFail = (error: string): IFluxStandardAction<string> => {
  return {
    type: batchActions.REMOVE_BATCH_FACULTY_FAIL,
    payload: error
  }
}

export const fetchUserPermissionsByBatchId = (batchId: number, userId: string) => {
  return {
    type: batchActions.FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID,
    payload: {
      batchId,
      userId
    }
  }
}

export const fetchUserPermissionsByBatchIdSuccess = (batchPermissions: IUserBatchPermissions, batchId: string) => {
  return {
    type: batchActions.FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID_SUCCESS,
    payload: {
      batchPermissions,
      batchId
    }
  }
}

export const setUserPermissionsByBatchId = (batchPermissions: IUserBatchPermissions, batchId: number) => {
  return {
    type: batchActions.SET_USER_BATCH_PERMISSIONS_BY_BATCH_ID,
    payload: {
      batchPermissions,
      batchId
    }
  }
}

export const fetchUserPermissionsByBatchIdFail = (error: string) => {
  return {
    type: batchActions.FETCH_USER_BATCH_PERMISSIONS_BY_BATCH_ID_FAIL,
    payload: error
  }
}


export const fetchInvitedStudents = (batchKey: string, page?: number, limit?: number, search?: string): IFetchInvitedStudentsAction => {
  return {
    type: batchActions.FETCH_INVITED_STUDENTS,
    payload: {
      batchKey,
      page,
      limit,
      search
    }
  }
};

export const fetchInvitedStudentsSuccess = (students: IInvitedStudent[], pageNumber: number) => {
  return {
    type: batchActions.FETCH_INVITED_STUDENTS_SUCCESS,
    payload: {
      students,
      pageNumber
    }
  }
};

export const fetchInvitedStudentsFail = (error: string): IFluxStandardAction<string> => {
  return {
    type: batchActions.FETCH_INVITED_STUDENTS_FAIL,
    payload: error,
  }
};

export const resendOTP = (emailIds: string[], batchKey: string, ccEmailIds?: string, instituteCustomUrl?: string): IResendOTPAction => {
  return {
    type: batchActions.RESEND_OPT,
    payload: { emailIds, ccEmailIds, batchKey, instituteCustomUrl }
  }
};

export const resendOTPSuccess = (payload: IResendOTPResponse) => {
  return {
    type: batchActions.RESEND_OPT_SUCCESS,
    payload,
  }
};

export const resendOTPFail = (error: string) => {
  return {
    type: batchActions.RESEND_OPT_FAIL,
    payload: error,
  }
};

// delete batch student
export const deleteBatchStudent = (payload: IDeleteBatchStudentPayload) => {
  return {
    type: batchActions.DELETE_BATCH_STUDENT,
    payload
  }
};

export const deleteBatchStudentSuccess = (payload: string) => {
  return {
    type: batchActions.DELETE_BATCH_STUDENT_SUCCESS,
    payload
  }
};

export const deleteBatchStudentFail = (error: string) => {
  return {
    type: batchActions.DELETE_BATCH_STUDENT_FAIL,
    payload: error
  }
};

export const uploadUsers = (data: FormData): IUploadUsersAction => {
  return {
    type: batchActions.UPLOAD_USERS,
    payload: { data }
  }
};
export const uploadUsersSuccess = (uploadUsers: IBulkUploadResponse) => {
  return {
    type: batchActions.UPLOAD_USERS_SUCCESS,
    payload: uploadUsers
  }
};

export const uploadUsersError = (error: string) => {
  return {
    type: batchActions.UPLOAD_USERS_FAIL,
    payload: error
  }
};

export const inviteSingleStudent = (batchKey: string, studentEmailIds: string): IInviteSingleStudentAction => {
  return {
    type: batchActions.INVITE_SINGLE_STUDENT,
    payload: {
      batchKey,
      studentEmailIds
    }
  }
};

export const inviteSingleStudentSuccess = (payload: any) => {
  return {
    type: batchActions.INVITE_SINGLE_STUDENT_SUCCESS,
    payload
  }
};
export const inviteSingleStudentFail = (error: string) => {
  return {
    type: batchActions.INVITE_SINGLE_STUDENT_FAIL,
    payload: error
  }
};


export const fetchStatusOfBulkUploadingById = (id: string): IFetchBulkUploadStatusAction => {
  return {
    type: batchActions.GET_STATUS_OF_BULK_UPLOADING_BYID,
    payload: { bulkUploadId: id }
  }
}

export const fetchStatusOfBulkUploadingByIdSuccess = (payload: IBulkUploadResponse) => {
  return {
    type: batchActions.GET_STATUS_OF_BULK_UPLOADING_BYID_SUCCESS,
    payload
  }
}

export const fetchStatusOfBulkUploadingByIdFail = (error: string) => {
  return {
    type: batchActions.GET_STATUS_OF_BULK_UPLOADING_BYID_FAIL,
    payload: error
  }
}


export const setAsessmentOpeningDates = (payload: ISetAssessmentOpeningDatePayload) => {
  return {
    type: batchActions.SET_ASSESSMENT_OPENING_DATES,
    payload
  }
}

export const setAssessmentOpeningDatesSuccess = (response) => {
  return {
    type: batchActions.SET_ASSESSMENT_OPENING_DATES_SUCCESS,
    payload: response
  }
}

export const setAssessmentOpeningDatesFail = (error) => {
  return {
    type: batchActions.SET_ASSESSMENT_OPENING_DATES_FAIL,
    payload: error
  }
}

export const getAsessmentOpeningDates = (batchId: string) => {
  return {
    type: batchActions.GET_ASSESSMENT_OPENING_DATES,
    payload: batchId
  }
}

export const getAssessmentOpeningDatesSuccess = (response) => {
  return {
    type: batchActions.GET_ASSESSMENT_OPENING_DATES_SUCCESS,
    payload: response
  }
}

export const getAssessmentOpeningDatesFail = (error) => {
  return {
    type: batchActions.GET_ASSESSMENT_OPENING_DATES_FAIL,
    payload: error
  }
}

